import React, { useState, useEffect, useContext, useRef } from 'react';
import s from "./Devices.module.css"
import { UserContext } from "../../App";
import TreeDiv from '../AddressTree/AddressTree';
import { exportDevicesToExcel } from '../../store/exportToExcel';
import { Link, useLocation } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';


function formatDate(lastDateTime) {
    if (lastDateTime === 0) {
        return "Нет данных";
    } else {
        return new Date(lastDateTime * 1000).toLocaleDateString('ru-RU');
    }
}

export default function Devices({ onButtonClick, handleLogsClick }) {
    const isMobile = useIsMobile();

    const [searchQuery, setSearchQuery] = useState('');
    const [searchAddressQuery, setSearchAddressQuery] = useState('');
    const { user, setUser } = useContext(UserContext);
    const [total, setTotal] = useState(null)
    const [devicesList, setDevice] = useState([])
    const [fullAddress, setFullAddress] = useState([]);
    const [openTree, setOpenTree] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const popupRef = useRef(null);
    const buttonRef = useRef(null);
    const popupRefM = useRef(null);
    const buttonRefM = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);

    const [sortOrder, setSortOrder] = useState({});
    const [order, setOrder] = useState('')

    const tableProps = {
        devicesList,
        total,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemPerPage,
        sortOrder,
        setSortOrder,
        order,
        setOrder
    }

    const prevDependencies = useRef({
        fullAddress: [],
        currentPage: 1,
        itemsPerPage: 10,
        searchQuery: '',
        order: null
    });

    useEffect(() => {
        async function fetchDevice() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });

                url.searchParams.append('limit', itemsPerPage);
                url.searchParams.append('offset', (currentPage * itemsPerPage) - itemsPerPage);
                url.searchParams.append('address', fullAddress)
                url.searchParams.append('search', searchQuery)
                url.searchParams.append('order', order)


                let response = await fetch(url);
                const data = await response.json();

                const { devices } = data
                const { total } = data

                console.log(data);


                setDevice(devices);
                setTotal(total);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchDevice();
    }, [currentPage, itemsPerPage, fullAddress, searchQuery, order]);


    const handleFullAddressChange = (address) => {
        if (JSON.stringify(fullAddress) !== JSON.stringify(address)) {
            setFullAddress(address);
        }
    };

    const handleItemClick = (event) => {
        event.stopPropagation();
        setOpenTree(true);
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setOpenMenu(prevState => !prevState);
    };

    const handleExportClick = async (event) => {
        try {
            await exportDevicesToExcel({ user });
        } catch (error) {
            console.error('Error exporting devices:', error);
        }
    };

    const cancelClick = () => {
        setFullAddress([])
        setDevice([])
    }

    const handleClickOutside = (event) => {
        if (
            popupRef.current && !popupRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpenTree(false);
        }
    };

    const handleClickOutsideM = (event) => {
        if (
            popupRefM.current && !popupRefM.current.contains(event.target) &&
            buttonRefM.current && !buttonRefM.current.contains(event.target)
        ) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideM);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideM);
        };
    }, []);

    return (
        <div className={s.main}>
            <div className={s.searchFrame}>
                <input
                    type='text'
                    className={s.search}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder='Поиск по imei, devEUI или номеру'
                >
                </input>
                <img src={'/search.svg'}></img>
            </div>
            {!isMobile ? (
                <div className={s.actionFrame}>
                    <div className={s.devImport} onClick={onButtonClick}>
                        <div className={s.devImportText}>Импорт устройств</div>
                    </div>
                    <div className={s.frame13} onClick={handleExportClick}>
                        <div className={s.frame13Text}>Экспорт файла</div>
                    </div>
                    <div className={s.frame14} onClick={handleLogsClick}>
                        <img src={'/log.svg'} alt="" />
                        <div className={s.frame14Text}>Лог</div>
                    </div>
                    <div className={s.frame15}>
                        <img src={'/add.svg'} alt="" />
                        <div className={s.frame15Text}>Добавить</div>
                    </div>

                </div>
            ) : (
                <div className={s.actionMobileFrame} onClick={handleMenuClick} ref={buttonRefM}>
                    <div className={s.warpFrame} >

                        <div className={s.actionMobileText}>Действия</div>
                        <div className={s.arrowDown}>
                            <img src={'/arrowback.svg'}></img>

                        </div>
                    </div>

                    {openMenu && (
                        <div className={s.menuPopup} ref={popupRefM}>
                            <div className={s.menuPopupWarp}>
                                <div className={s.devImport} onClick={onButtonClick}>
                                    <div className={s.devImportText}>Импорт устройств</div>
                                </div>
                                <div className={s.frame13} onClick={handleExportClick}>
                                    <div className={s.frame13Text}>Экспорт файла</div>
                                </div>
                                <div className={s.frame14} onClick={handleLogsClick}>
                                    <img src={'/log.svg'} alt="" />
                                    <div className={s.frame14Text}>Лог</div>
                                </div>
                                <div className={s.frame15}>
                                    <img src={'/add.svg'} alt="" />
                                    <div className={s.frame15Text}>Добавить</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className={s.addressWarp}>
                <div className={s.addressFrame} onClick={handleItemClick} ref={buttonRef}>
                    <div className={s.frame75} >
                        <div className={s.frame75logo}>
                            <img src={'/home.svg'}></img>
                        </div>
                        <div className={s.frame75Text}>Список адресов</div>
                        <div className={s.arrowDown}>
                            <img src={'/arrowback.svg'}></img>

                        </div>
                    </div>
                    {openTree && (
                        <div className={s.addressPopup} ref={popupRef}>
                            <div className={s.address}>
                                <div className={s.addressInput}>
                                    <input
                                        type='text'
                                        className={s.addressSearch}
                                        value={searchAddressQuery}
                                        onChange={(e) => setSearchAddressQuery(e.target.value)}
                                        placeholder='Поиск адреса'
                                    >
                                    </input>
                                    <img src={'/search.svg'}></img>
                                </div>
                                <div>
                                    <TreeDiv setSelectedAddress={(address) => {
                                        setSelectedAddress(address);
                                    }} handleFullAddressChange={handleFullAddressChange} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {fullAddress.length > 0 && (
                    <div className={s.chosedAddr} onClick={cancelClick} >
                        <div className={s.chosedAddrImg}>
                            <img
                                src={'/cancel.svg'}
                                alt="cancel"
                            />
                        </div>
                        <div className={s.chosedAddrText}>
                            {fullAddress.join(', ')}
                        </div>
                        
                    </div>
                )}
            </div>
            <Table {...tableProps} />
            {!isMobile ? (
                <></>
            ) : (
                <div className={s.podval}>
                </div>
            )}
        </div>
    )
}


const Table = (tableProps) => {
    const headers = ['Статус', 'Тип', 'Номер счетчика', 'DevEUI/Imei', 'Адрес', 'Посл. показания', 'Дата']
    const { devicesList, total, itemsPerPage, currentPage, setItemPerPage, setCurrentPage, sortOrder, setSortOrder, order, setOrder } = tableProps;

    const headerKeys = {
        'Статус': 'status',
        'Тип': 'resourse_type',
        'Номер счетчика': 'dev_number',
        'DevEUI/Imei': 'deveui,serialnumber',
        'Адрес': 'address',
        'Посл. показания': 'acc_volume',
        'Дата': 'last_date_time',
        'Владелец': 'ownership',
        'Форма собственности': 'type_ownership',
        'Описание устройства': 'info',
        'Дата окончания поверки': 'verification_end_date'
    };

    const sortData = (field) => {
        const newSortOrder = sortOrder[field] === 1 ? -1 : 1;

        setSortOrder({
            ...sortOrder,
            [field]: newSortOrder
        });

        const orderField = newSortOrder === 1 ? field : `-${field}`;
        setOrder(orderField);
    };


    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(total / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const totalPages = Math.ceil(total / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    return (
        <div className={s.tableContainer}>
            <table className={s.deviceTable}>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th key={header} onClick={() => headerKeys[header] && sortData(headerKeys[header])}>
                                <div className={s.warp}>
                                    <div className={s.frame48Text}>{header}</div>
                                    <div className={s.tableArrowDown}>

                                        <img
                                            src={sortOrder[headerKeys[header]] === 1 ? '/tablearrowup.svg' : '/tablearrowdown.svg'}
                                            alt="sort"
                                        />
                                    </div>
                                </div>
                            </th>
                        ))}
                        <th>
                            <div className={s.setting}>
                                <img src={'/setting.svg'}></img>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {devicesList.map(device => (
                        <tr key={device.id}>
                            <td className={s.status}>
                                <div className={s.warp}>
                                    <div className={s.statusElipse}>
                                        <img src={`/${device.data[0].status.toLowerCase()}.svg`} alt="" />
                                    </div>
                                    <div className={s.lock}>
                                        <img src={'/lock.svg'} />
                                    </div>
                                </div>
                            </td>
                            {device.device[0].dev_type == "ВС" && device.device[0].resourse_type == "Горячая вода" ? <td data-label="Тип:" className={s.redText}>ГВС</td>
                                : device.device[0].dev_type == "ВС" && device.device[0].resourse_type == "Холодная вода" ? <td data-label="Тип:" className={s.blueText}>ХВС</td>
                                    : device.device[0].dev_type == "ТС" ? <td data-label="Тип:">ТС</td>
                                        : <td data-label="Тип:" className={s.blueText}>ВС</td>}
                            <td data-label="Номер счетчика:" className={s.tableListText}>
                                {device.device[0].dev_number}
                            </td>
                            <td data-label="DevEUI/Imei:" className={s.tableListText}>
                                {device.device_iot?.deveui || device.device_iot?.serialnumber || ""}
                            </td>
                            <td className={s.addressText}>
                                {`${device.address[0].country ? device.address[0].country + ', ' : ''}` +
                                    `${device.address[0].region ? device.address[0].region + ', ' : ''}` +
                                    `${device.address[0].city ? device.address[0].city + ', ' : ''}` +
                                    `${device.address[0].street ? device.address[0].street + ', ' : ''}` +
                                    `${device.address[0].building ? device.address[0].building + (device.address_number !== null ? ', ' : '') : ''}` +
                                    `${device.address_number !== null && device.address_number !== undefined ? device.address_number : ''}`}
                            </td>
                            <td data-label="Посл. показания:" className={s.tableListText}>
                                {`${(parseFloat(device.data[0].last_volume + device.data[0].start_volume) * (device.device[0].factor)).toFixed(3)} 
                    ${device.device[0].dev_type === 'ВС' ? 'м³'
                                        : device.device[0].dev_type === 'ТС' ? 'ГДж'
                                            : 'кВт-Ч'}`
                                }
                            </td>
                            <td data-label="Дата показаний:" className={s.tableListText}>
                                {formatDate(device.data[0].last_date_time)}
                            </td>
                            <td className={s.details}>
                                <Link to={`/device/${device._id}&${device.device[0].dev_number}`} id={`${device.device[0].dev_number}`}>
                                    <img src={'/document.svg'} />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={s.paginate}>
                <div className={s.quantityDevice}>
                    {total} всего
                </div>
                <div className={s.devicePerPage}>
                    <div className={s.devicePerPageText}>Показать</div>
                    <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className={s.currentPage}>
                    <div className={s.arrowLeft} onClick={handlePreviousPage}>
                        <img src={'/arrowleft.svg'}></img>
                    </div>
                    <div className={s.page}> {currentPage}</div>
                    <div className={s.arrowRight} onClick={handleNextPage}>
                        <img src={'/arrowright.svg'}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}
