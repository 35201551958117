import './App.css';
import React, { createContext, useState, useEffect, useContext } from 'react';
import LoginForm from './Components/Login/Login';
import LayoutMap from './Components/Layout/LayoutMap';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Registration from './Components/Registration/Registration';
import LayoutDevices from './Components/Layout/LayoutDevices';
import LayoutSettings from './Components/Layout/LayoutSettings';
import LayoutReports from './Components/Layout/LayoutReports';
import Header from './Components/Layout/Header';
import s from "./Components/Layout/Layout.module.css"
import { QueryClient, QueryClientProvider } from 'react-query';


const UserContext = createContext();
const queryClient = new QueryClient();


const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const localUser = window.localStorage.getItem('user');
    return localUser ? JSON.parse(localUser) : null;
  });

  useEffect(() => {
    window.localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

const App = () => {
  const [username, setUsername] = useState('');
  const { user, setUser } = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const savedLoginState = localStorage.getItem('isLoggedIn');
    return savedLoginState ? JSON.parse(savedLoginState) : false;
  });


  const handleLogin = (userData) => {
    setUsername(userData.username);
    setUser(userData);
    setIsLoggedIn(true);
    localStorage.setItem('username', username);
    localStorage.setItem('isLoggedIn', JSON.stringify(true));
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/devices" /> : <LoginForm onLogin={handleLogin} />}
          />
          <Route
            path="login"
            element={user ? <Navigate to="/devices" /> : <LoginForm onLogin={handleLogin} />}
          />
          <Route
            path="registration"
            element={user ? <Navigate to="/devices" /> : <Registration />}
          />

          <Route
            path="*"
            element={
              user ? (
                <>
                  <div className={s.main}>
                    <Header />
                    <Routes>
                      <Route path="map" element={<LayoutMap />} />
                      <Route path="devices" element={<LayoutDevices />} />
                      <Route path="device/:id" element={<LayoutDevices />} />
                      <Route path="reports" element={<LayoutReports />} />
                      <Route path="settings" element={<LayoutSettings />} />
                    </Routes>
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>

      </div>
    </Router>
  );
}

export default function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <App />
      </UserProvider>
    </QueryClientProvider>
  );
}
