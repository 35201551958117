import React, { useState } from 'react';

import s from "./Layout.module.css"
import Sidebar from './Sidebar';

import Reports from '../Reports/Reports';
import LogsReport from './LogsReport';
import useIsMobile from '../../store/mobileHook';





export default function LayoutReports() {
    const isMobile = useIsMobile();

    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeMenuItem, setActiveMenuItem] = useState('item3')
    const [activeComponent, setActiveComponent] = useState('Reports');

    const handleLogsClick = () => {
        setActiveComponent('Logs');
    };

    const handleBackClick = () => {
        setActiveComponent('Reports');
    };

    return (
        
            <div className={s.container}>
                {!isMobile ? (
                    <>
                        {activeSideBar ? (
                            <Sidebar setActiveSideBar={setActiveSideBar} setActiveMenuItem={setActiveMenuItem} setActiveComponent={setActiveComponent} />
                        ) : (
                            <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                                <img src={'/showlogo.svg'}></img>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )
                }
                <div className={s.content}>
                    {activeComponent === 'Reports' ? (
                        <Reports handleLogsClick={handleLogsClick} />
                    ) : activeComponent === 'Logs' ? (
                        <LogsReport handleBackClick={handleBackClick} />
                    ) : (null)}
                </div>
            </div>

    )
}