export async function fetchDeviceData(deviceId) {
  

  const [deviceUuid, deviceNumber] = deviceId.split('&');

  const url = new URL(`${process.env.REACT_APP_API_URL}/device/detail`);
  

  url.searchParams.append('_id', deviceUuid);
  url.searchParams.append('dev_number', deviceNumber);

  let response = await fetch(url);
  let data = await response.text();
  let obj = JSON.parse(data);

  return obj;

}

export async function getDevDetailReports(id, dev_number, fromDate, toDate, selectedOption) {
  

  const formData = new FormData();
  formData.append('fromDate', fromDate);
  formData.append('toDate', toDate);
  formData.append('detail', selectedOption);

  const url = new URL(`${process.env.REACT_APP_API_URL}/reports/device`);
  url.searchParams.append('_id', id);
  url.searchParams.append('dev_number', dev_number);

  try {
    let response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`POST request failed: ${response.status}`);
    }

    let data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}



export async function getAddress(user) {
  

  const url = new URL(`${process.env.REACT_APP_API_URL}/address/get`);

  user.cs_group.forEach(csGroup => {
    url.searchParams.append('cs_group_id', csGroup._id);
  });
  user.nbiot_group.forEach(nbiotGroup => {
    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
  });

  let response = await fetch(url);

  let data = await response.text();
  let obj = JSON.parse(data);

  return obj;
}


export async function getDeviceById(deviceId, deviceNumber) {
  

  const url = new URL(`${process.env.REACT_APP_API_URL}/device/detail`);

  url.searchParams.append('_id', deviceId);
  url.searchParams.append('dev_number', deviceNumber);

  let response = await fetch(url);
  let data = await response.text();
  let obj = JSON.parse(data);
  return obj;
}



