import React, { useState } from 'react';
import s from "./DevicesImport.module.css"
import { DeviceForm, DevicesEditForm, DevicesImportForm, DevicesNbiotEditForm, DevicesNbiotImportForm } from './Forms';
import example from './example.xlsx'
import examplenbiot from './examplenbiot.xlsx'


function DeviceImportData({ onBackClick }) {
    const handleButtonClickLora = () => {
        const link = document.createElement('a');
        link.href = example;
        link.download = 'example.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleButtonClickNbiot = () => {
        const link = document.createElement('a');
        link.href = examplenbiot;
        link.download = 'examplenbiot.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={s.importGrid}>
            <div className={s.forwardback} onClick={onBackClick}>
                <div className={s.backIcon} >
                    <img src={'/forwardback.svg'}></img>
                </div>
                <div className={s.forwardbackText}>Назад</div>
            </div>
            <div className={s.add}>
                <div className={s.formAdd}>
                    <div className={s.formTittle}>
                        Добавление устройства в Chirpstack
                    </div>

                    <DeviceForm />
                </div>
            </div>
            <TabsContainer>
                <TabPanel label="Устройства LoRa">
                    <div className={s.box}>
                        <div className={s.importBox} >
                            <div className={s.formAddLora}>
                                <div className={s.titleContainer}>
                                    <div className={s.formTittle}>
                                        Импорт устройств
                                    </div>
                                    <div className={s.downland} onClick={handleButtonClickLora}>
                                        <img src={'/downland.svg'} alt="" />
                                        <div className={s.downlandText}>Скачать шаблон</div>
                                    </div>
                                </div>
                                <DevicesImportForm />
                            </div>
                        </div>
                        <div className={s.bord}></div>
                        <div className={s.importBox}>
                            <div className={s.formAddLora}>
                                <div className={s.formTittle}>
                                    Редактирование устройств
                                </div>
                                <DevicesEditForm />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel label="Устройства NBIoT">
                    <div className={s.box}>
                        <div className={s.importBox} >
                            <div className={s.formAddLora}>
                                <div className={s.titleContainer}>
                                    <div className={s.formTittle}>
                                        Импорт устройств
                                    </div>
                                    <div className={s.downland} onClick={handleButtonClickNbiot}>
                                        <img src={'/downland.svg'} alt="" />
                                        <div className={s.downlandText}>Скачать шаблон</div>
                                    </div>
                                </div>
                                <DevicesNbiotImportForm />
                            </div>
                        </div>
                        <div className={s.bord}></div>
                        <div className={s.importBox}>
                            <div className={s.formAddLora}>
                                <div className={s.formTittle}>
                                    Редактирование устройств
                                </div>
                                <DevicesNbiotEditForm />
                            </div>
                        </div>
                    </div>

                </TabPanel>
            </TabsContainer>
        </div>
    )
}

const TabsContainer = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const handleClick = (label) => {
        setActiveTab(label);
    };

    return (
        <div className={s.tabWrap} >
            <div className="tabs">
                {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={handleClick}
                        />
                    );
                })}
            </div>
            <div className={s.tabContent}>
                {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

const Tab = ({ activeTab, label, onClick }) => {
    let className = 'tab-list-item';

    if (activeTab === label) {
        className += ' tab-list-active';
    }

    return (
        <button className={className} onClick={() => onClick(label)}>
            <div className={s.formTittle}>{label}</div>
        </button>
    );
};

const TabPanel = ({ children }) => {
    return (
        <div>
            {children}
        </div>
    );
};

export default DeviceImportData;