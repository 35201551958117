import { React, useState, useContext } from 'react';
import s from "../SettingData/SettingData.module.css"
import { UserContext } from "../../App";
import { useFormik } from 'formik';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Typography, Autocomplete } from '@mui/material';
import ModalTemplate from "../ModalForms/ModalForms";
import { ApplicationForm, useHideMessage } from '../SettingData/Forms';
import { NbiotGroupForm } from './NbiotForm';


export const AddressFormNbiot = ({ onSuccess }) => {
    const { user, setUser } = useContext(UserContext);
    const [openGroup, setOpenGroup] = useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);

    const REGIONS = [
        "Минская",
        "Брестская",
        "Витебская",
        "Гомельская",
        "Гродненская",
        "Могилевская"
    ]

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            application: "",
            country: "Беларусь",
            region: "",
            city: "",
            street: "",
            building: ""
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/address/createNbiot`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                console.log(data);
                if (onSuccess) {
                    onSuccess(data.address);
                }
                setSubmitStatus('success');
                resetForm();

            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Добавление адреса
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств NBIoT</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="application"
                    name="application"
                    label="Группа устройств NBIoT"
                    value={formik.values.application}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value="" onClick={handleOpenGroup}>
                        + Создать новую группу
                    </MenuItem>
                    {user.nbiot_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>

                <ModalTemplate
                    open={openGroup}
                    onClose={handleCloseGroup}
                >
                    <NbiotGroupForm onSuccess={handleCloseGroup} />
                </ModalTemplate>
            </FormControl>

            <TextField
                fullWidth
                margin="normal"
                id="country"
                name="country"
                label="Страна"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
            />
            <FormControl fullWidth margin="normal" sx={{ marginTop: 1 }}>
                <InputLabel>Область</InputLabel>
                <Select
                    fullWidth
                    id="region"
                    name="region"
                    label="Область"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                >
                    {REGIONS.map((region, index) => (
                        <MenuItem key={index} value={region}>
                            {region}
                        </MenuItem>
                    ))}
                </Select>
                {formik.touched.region && formik.errors.region && (
                    <div style={{ color: 'red' }}>{formik.errors.region}</div>
                )}
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                id="city"
                name="city"
                label="Город"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
                fullWidth
                margin="normal"
                id="street"
                name="street"
                label="Улица"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
            />
            <TextField
                fullWidth
                margin="normal"
                id="building"
                name="building"
                label="Дом"
                value={formik.values.building}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.building && Boolean(formik.errors.building)}
                helperText={formik.touched.building && formik.errors.building}
            />
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}

export const AddressFormChirpStack = ({ onSuccess }) => {
    const { user, setUser } = useContext(UserContext);
    const [openGroup, setOpenGroup] = useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);

    const REGIONS = [
        "Минская",
        "Брестская",
        "Витебская",
        "Гомельская",
        "Гродненская",
        "Могилевская"
    ]

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            application: "",
            country: "Беларусь",
            region: "",
            city: "",
            street: "",
            building: ""
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/address/createChirpstack`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json(); // Декодируем JSON из ответа
                console.log(data);
                if (onSuccess) {
                    onSuccess(data.address);
                }
                setSubmitStatus('success');
                resetForm();

            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Добавление адреса
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств LoRa</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="application"
                    name="application"
                    label="Группа устройств LoRa"
                    value={formik.values.application}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value="" onClick={handleOpenGroup}>
                        + Создать новую группу
                    </MenuItem>
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>

                <ModalTemplate
                    open={openGroup}
                    onClose={handleCloseGroup}
                >
                    <ApplicationForm onSuccess={handleCloseGroup} />
                </ModalTemplate>
            </FormControl>

            <TextField
                fullWidth
                margin="normal"
                id="country"
                name="country"
                label="Страна"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
            />
            <FormControl fullWidth margin="normal" sx={{ marginTop: 1 }}>
                <InputLabel>Область</InputLabel>
                <Select
                    fullWidth
                    id="region"
                    name="region"
                    label="Область"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                >
                    {REGIONS.map((region, index) => (
                        <MenuItem key={index} value={region}>
                            {region}
                        </MenuItem>
                    ))}
                </Select>
                {formik.touched.region && formik.errors.region && (
                    <div style={{ color: 'red' }}>{formik.errors.region}</div>
                )}
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                id="city"
                name="city"
                label="Город"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
                fullWidth
                margin="normal"
                id="street"
                name="street"
                label="Улица"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
            />
            <TextField
                fullWidth
                margin="normal"
                id="building"
                name="building"
                label="Дом"
                value={formik.values.building}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.building && Boolean(formik.errors.building)}
                helperText={formik.touched.building && formik.errors.building}
            />
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}