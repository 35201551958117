import React, { useState, useEffect, useContext } from 'react';
import s from "./Header.module.css"
import { UserContext } from "../../App";
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';
import Sidebar from './Sidebar';
import { useQuery } from 'react-query';

const fetchDeviceList = async (user) => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/label/get`);
  
    user.cs_group.forEach(csGroup => {
      url.searchParams.append('cs_group_id', csGroup._id);
    });
    
    user.nbiot_group.forEach(nbiotGroup => {
      url.searchParams.append('nbiot_group_id', nbiotGroup._id);
    });
  
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };


export default function Header() {
    const isMobile = useIsMobile();
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeComponent, setActiveComponent] = useState('Devices');

    const { data: deviceList, error, isLoading } = useQuery(
        ['deviceList', user], 
        () => fetchDeviceList(user), 
        {
            enabled: !!user, 
            refetchInterval: 60000, 
            staleTime: 60000, 
            cacheTime: 5 * 60 * 1000, 
            refetchOnWindowFocus: true, 
        }
    );
    
    useEffect(() => {
        if (isMobile) {
            setActiveSideBar(false);
        }
    }, [location, isMobile]);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
        setUser("");
    };

    const handleMenuToggle = () => {
        setActiveSideBar(prevState => !prevState);
    };    

    return (
        <div className={s.header}>
            {!isMobile ? (
                <div className={s.logo}></div>
            ) : (
                <>
                    <div className={s.mobileMenu} onClick={handleMenuToggle}>
                        <img src={'/menu.svg'}></img>
                    </div>
                    <div className={s.logo}></div>
                    <div className={s.mobileUser}>
                        <img src={'/user.svg'}></img>
                    </div>
                    {activeSideBar ? (
                        <Sidebar setActiveSideBar={setActiveSideBar} setActiveComponent={setActiveComponent} handleMenuToggle={handleMenuToggle} />
                    ) : (
                        <></>
                    )}
                </>
            )
            }

            {!isMobile ? (<>
                <div className={s.label}>
                    <div className={s.grid1}>
                        <span>всего устройств</span>
                        <div className={s.gridItem1}>
                            <span>{deviceList?.totalRows}</span>
                        </div>
                    </div>
                    <div className={s.grid2}>
                        <span>в сети</span>
                        <div className={s.gridItem2}>
                            <span>{deviceList?.onlineRows}</span>
                        </div>
                    </div>
                    <div className={s.grid3}>
                        <span>с ошибками</span>
                        <div className={s.gridItem3}>
                            <span>{deviceList?.problemRows}</span>
                        </div>
                    </div>
                    <div className={s.grid4}>
                        <span>не в сети</span>
                        <div className={s.gridItem4}>
                            <span>{deviceList?.warnRows}</span>
                        </div>
                    </div>
                </div>
                <div className={s.user}>
                    <span>{user.email}</span>
                    <div className={s.userIcon} onClick={handleLogout}>
                        <img src={'/logout.svg'}></img>
                    </div>
                </div>
            </>
            ) : (<></>)
            }
        </div>
    )
}

