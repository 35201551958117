import React, { useState } from 'react';
import s from "./Layout.module.css"
import Sidebar from './Sidebar';
import MapBox from "../MapBox/MapBox"
import useIsMobile from '../../store/mobileHook';


export default function Layout() {
    const isMobile = useIsMobile();

    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeComponent, setActiveComponent] = useState('Devices');


    return (
        
            <div className={s.container}>
                { !isMobile ? (
                    <>{activeSideBar ? (
                        <Sidebar setActiveSideBar={setActiveSideBar} setActiveComponent={setActiveComponent}/>
                    ) : (
                        <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                            <img src={'/showlogo.svg'}></img>
                        </div>
                    )}</>
                ) : (
                     <></>   
                )}
                

                <div className={s.content}>
                    <div className={s.map}>
                        <MapBox />
                    </div>
                </div>
            </div>

    )
}