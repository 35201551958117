import React, { useState, useEffect, useContext, useRef } from 'react';
import s from "./DeviceInfo.module.css"
import { getDevDetailReports, getDeviceById } from '../../store/deviceSlice'
import { UserContext } from "../../App";
import { fetchDeviceData } from "../../store/deviceSlice";
import PropTypes from 'prop-types';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { BarChart } from '@mui/x-charts/BarChart';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { TextField, Box, Button, Typography } from '@mui/material';
import { exportDeviceToExel, exportTemplatesXLSX } from '../../store/exportToExcel';
import { useNavigate } from 'react-router-dom';
import { axisClasses } from '@mui/x-charts/ChartsAxis';


export default function DeviceInfo({ deviceId, onBackClick, handleInfoLogsClick }) {

    const [deviceUuid, deviceNumber] = deviceId.split('&');

    const [deviceInfo, setDeviceInfo] = useState(null);
    const { user, setUser } = useContext(UserContext);
    const [editingDevice, setEditingDevice] = useState(false);
    const [selectedOption, setSelectedOption] = useState(1);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (user && user.nbiot_org && user.nbiot_org.length > 0) {
            const nbiotOrg = user.nbiot_org[0];
            if (nbiotOrg.template) {
                const templateArray = Object.values(nbiotOrg.template);
                setTemplates(templateArray);
            }
        }
    }, [user]);

    const org = user.nbiot_org && user.nbiot_org.length > 0 ? user.nbiot_org[0]._id : null;



    const navigate = useNavigate();

    const handleBackClick = () => {
        onBackClick();
        navigate('/devices');
    };

    useEffect(() => {
        getDeviceInfo(deviceUuid, deviceNumber, user).then(info => setDeviceInfo(info));
    }, []);

    const displayNames = {
        user: 'Пользователь',
        device: 'Устройство',
        deviceIoT: 'IoT Устройство',
        data: 'Данные',
        paidInfo: 'Платежная Информация'
    };

    const handleEditClick = () => {
        setEditingDevice(true);
    };

    const closeModal = () => {
        setEditingDevice(false);
    };


    if (!deviceInfo) {
        return null;
    }

    const getClassForStatus = (status) => {
        switch (status.toLowerCase()) {
            case 'problem':
                return s.ownerLogoproblem;
            case 'online':
                return s.ownerLogoonline;
            case 'warn':
                return s.ownerLogowarn;
            default:
                return '';
        }
    };

    const statusClass = getClassForStatus(deviceInfo.status);

    return (
        <div className={s.infoGrid}>
            <div className={s.forwardback} onClick={handleBackClick}>
                <div className={s.backIcon}>
                    <img src={'/forwardback.svg'}></img>
                </div>
                <div className={s.forwardbackText}>Назад</div>
            </div>

            <div className={s.buttonsGroup}>
                <div className={s.logs} onClick={handleInfoLogsClick}>
                    <div className={s.logsIcon}>
                        <img src={'/log.svg'}></img>
                    </div>
                    <div className={s.logsText}>Лог</div>
                </div>
                <div className={s.copyCard}>
                    <div className={s.copyCardText}>Дублировать карточку устройства</div>
                </div>
            </div>

            <div className={s.title}>
                <div className={s.owner}>
                    <div className={s.ownerText}>{deviceInfo.user.ownership}</div>
                    <div className={statusClass}>
                        <div className={s.ownerLogoWarp}>
                            <img src={`/${deviceInfo.status.toLowerCase()}.svg`} alt="" />
                            <img src={'/lock.svg'}></img>
                        </div>
                    </div>
                </div>
                <div className={s.address}>
                    {deviceInfo.user.address}
                </div>
            </div>
            <div className={s.infoWarp}>
                <div className={s.device}>
                    <div className={s.deviceTitle}>
                        {displayNames.device}
                    </div>
                    {Object.entries(deviceInfo.device).map(([key, value]) => (
                        <div className={s.listItem}>
                            <div className={s.listItemKey}>{key}</div>
                            <div className={s.listItemValue}>{value}</div>
                        </div>
                    ))}
                </div>

                <div className={s.deviceIot}>
                    <div className={s.deviceTitle}>
                        {displayNames.deviceIoT}
                    </div>
                    {Object.entries(deviceInfo.deviceIoT).map(([key, value]) => (
                        <div className={s.listItem}>
                            <div className={s.listItemKey}>{key}</div>
                            <div className={s.listItemValue}>{value}</div>
                        </div>
                    ))}
                </div>

                <div className={s.paidInfo}>
                    <div className={s.deviceTitle}>
                        {displayNames.paidInfo}
                    </div>
                    {Object.entries(deviceInfo.paidInfo).map(([key, value]) => (
                        <div className={s.listItem}>
                            <div className={s.listItemKey}>{key}</div>
                            <div className={s.listItemValue}>{value}</div>
                        </div>
                    ))}
                </div>

                <div className={s.data}>
                    <div className={s.deviceTitle}>
                        {displayNames.data}
                    </div>
                    {Object.entries(deviceInfo.data).map(([key, value]) => (
                        <div className={s.listItem}>
                            <div className={s.listItemKey}>{key}</div>
                            <div className={s.listItemValue}>{value}</div>
                        </div>
                    ))}
                </div>

                <div className={s.editButton} onClick={handleEditClick}>
                    <img src={'/edit.svg'}></img>
                    <div className={s.editText}>Редактировать</div>
                </div>

            </div>

            <Table deviceId={deviceId} templates={templates} org={org} />

            <div className={s.graphContainer}>
                <div className={s.graphActions}>
                    <div className={s.graphTitle}>График общего потребления</div>
                    <select className={s.graphSelect} onChange={(e) => setSelectedOption(e.target.value)}>
                        <option value="1">По месяцам</option>
                        <option value="0">По дням</option>
                    </select>
                </div>

                {/* <div className={s.graphDateForm}>
                    <input type='date' className={s.startDate}></input>
                    <div className={s.decil}></div>
                    <input type='date' className={s.endDate}></input>
                </div> */}
                <CustomBarChart id={deviceUuid} dev_number={deviceNumber} aggr_type={selectedOption} />
                {/* <div className={s.lastData}>
                    <img src={'/circlelight.svg'}></img>
                    <div className={s.lastDataText}>Прошлые показания</div>
                </div>
                <div className={s.currentData}>
                    <img src={'/circledark.svg'}></img>
                    <div className={s.currentDataText}>Текущие показания</div>
                </div> */}

            </div>
            {editingDevice && (
                <DeviceEdit deviceId={deviceId} user={user} isOpen={!!editingDevice} closeModal={closeModal} />
            )}
            <div className={s.empty}></div>
        </div>
    )
}


async function getDeviceInfo(deviceId, deviceNumber) {
    const device = await getDeviceById(deviceId, deviceNumber);
    const { address, device: [meter], device_iot: deviceIot, time_data: time_data, data, paid_info: [paidInfo] } = device;

    const addressString = `${address.country}, ${address.region}, ${address.city}, ${address.street}, ${address.building}, ${device.address_number}`;

    return {
        user: {
            'ownership': paidInfo.ownership,
            'address': addressString
        },
        device: {
            'Тип счетчика': meter.dev_type,
            'Заводской номер': meter.dev_number,
            'Тип ресурса': meter.resourse_type,
            'Марка': meter.brand,
            'Диаметр (корпус)': meter.diameter_body,
            'Комментарий к устройсвту': meter.commit,
            'Дата окончания поверки': new Date(meter.verification_end_date * 1000).toLocaleDateString('ru-RU'),
            'Множитель': meter.factor
        },
        deviceIoT: {
            'Тип устройства': deviceIot.dev_type_iot,
            'Марка': deviceIot.brand_iot,
            'DevEUI': deviceIot.deveui,
            'Токен': deviceIot.token,
            'Производитель': deviceIot.manufacture
        },
        data: {
            'Последние полученные данные': new Date(time_data.last_taken_data * 1000).toLocaleDateString('ru-RU'),
            'Количество подключений в день': time_data.quantity_connections,
            'Следующая передача данных': new Date(time_data.following_data_transfer * 1000).toLocaleDateString('ru-RU')
        },
        paidInfo: {
            'Лицевой счет': paidInfo.accountnumber,
            'Абонентский район': paidInfo.subscriptionarea,
            'Реестр': paidInfo.registry,
            'Код прибора в ЕРИП': paidInfo.devicecode,
            'Владелец': paidInfo.ownership
        },
        status: device.data[0].status
    };
}


const Table = ({ deviceId, templates, org }) => {
    const [deviceUuid, deviceNumber] = deviceId.split('&');
    const [data, setData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [filteredData, setFilteredData] = useState(null);

    const headers = ['Дата показаний', 'Общее потребление', 'Потребление']

    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        return sevenDaysAgo.toISOString().substring(0, 10);
    });
    const [endDate, setEndDate] = useState(() => {
        const currentDate = new Date();
        return currentDate.toISOString().substring(0, 10);
    });

    const [selectedOption, setSelectedOption] = useState('byDay');

    const handleSelection = (event) => {
        const selected = event.target.value;
        setSelectedOption(selected);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (deviceId) {
                const result = await getDevDetailReports(deviceUuid, deviceNumber, startDate, endDate, selectedOption);
                setData(result);
            }
        };

        fetchData();
    }, [startDate, endDate, selectedOption]);

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest(`.${s.downland}`)) {
                setShowPopup(false);
            }
        };

        if (showPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup]);

    useEffect(() => {
        if (data && data.parsingResult) {
            const filtered = data.parsingResult.filter((value) => {
                console.log(value);
                const timestamp = new Date(value.device_frmpayload_data_timestamp * 1000);
                const fromDateTimestamp = new Date(startDate);
                const toDateTimestamp = new Date(endDate);
                fromDateTimestamp.setHours(0, 0, 0, 0);
                toDateTimestamp.setHours(0, 0, 0, 0);
                return timestamp >= fromDateTimestamp;
            });
            console.log(filtered);
            if (JSON.stringify(filtered) !== JSON.stringify(filteredData)) {
                setFilteredData(filtered);
                // Update parent component
            }
        }
    }, [data, startDate, endDate, filteredData]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);

    let quantity = 0;
    if (data) {
        quantity = data.parsingResult.length;
    }

    let totalPages = 0;
    if (data) {
        totalPages = Math.ceil(data.parsingResult.length / itemsPerPage);
    }

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        if (totalPages > 1) {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        }
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const { user, setUser } = useContext(UserContext);
    const username = user.email

    const handleExportXLSX = () => {
        const dataToExport = {
            filteredData,
            data,
            selectedOption,
            username,
            startDate,
            endDate
        };
        exportDeviceToExel({ dataToExport }) // Вызов функции для отправки данных
            .then(() => {
                console.log('Данные успешно отправлены для отчета за период');
            })
            .catch(error => {
                console.error('Ошибка отправки данных для отчета за период:', error);
            });
    };


    const handleButtonClick = () => {

        if (data) {
            const device = data.device[0];
            const devType = device.device[0].dev_type;
            if (devType == 'ТС') {
                setShowPopup((prev) => !prev);
            } else {
                handleExportXLSX();
            }
        }

    };

    const handleExportClick = (template) => {
        handleExportTemplateXLSX(template);
        setShowPopup(false);
    };

    const handleExportTemplateXLSX = (template) => {
        const dataToExport = {
            deviceUuid,
            deviceNumber,
            org,
            template,
            startDate,
            endDate
        };
        exportTemplatesXLSX({ dataToExport }) // Вызов функции для отправки данных
            .then(() => {
                console.log('Данные успешно отправлены для отчета за период');
            })
            .catch(error => {
                console.error('Ошибка отправки данных для отчета за период:', error);
            });
    };


    return (
        <>
            <div className={s.warp}>
                <div className={s.actionTable}>
                    <div className={s.tableTitle}>Таблица потребления</div>

                    <select className={s.tableSelect} onChange={handleSelection}>
                        <option value="byDay">По дням</option>
                        <option value="byMonth">По месяцам</option>
                    </select>

                    <div className={s.dateForm}>
                        <input type='date' className={s.startDate} value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                        <div className={s.decil}></div>
                        <input type='date' className={s.endDate} value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                    </div>
                </div>
                <div className={s.divTable}>
                    <table className={s.table}>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>
                                        <div className={s.tableText}>{header}</div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <PaginationTable filteredData={filteredData} data={data} selectedOption={selectedOption} currentPage={currentPage} itemsPerPage={itemsPerPage} />
                        </tbody>
                    </table>
                </div>
                <div className={s.foterWrap}>
                    <div className={s.downland} onClick={handleButtonClick}>
                        <img src={'/downland.svg'} alt="" />
                        <div className={s.downlandText}>Экспорт данных</div>
                    </div>
                    {showPopup && (
                        <div className={s.popup} ref={popupRef}>
                            <div className={s.popupContent}>
                                {templates.map((header, index) => (
                                    <div className={s.downlandButton} key={index}
                                        onClick={() => handleExportClick(header)}   >
                                        <div className={s.downlandText}>{header}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className={s.paginate}>
                        <div className={s.paginateText}>{quantity} всего</div>
                        <div className={s.devicePerPage}>
                            <div className={s.devicePerPageText}>Показать</div>
                            <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                <option value="5">5</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div className={s.paginateInput}>
                            <div className={s.arrowLeft} onClick={handlePreviousPage}>
                                <img src={'/arrowleft.svg'}></img>
                            </div>
                            <div className={s.page}>{currentPage}</div>
                            <div className={s.arrowRight} onClick={handleNextPage}>
                                <img src={'/arrowright.svg'}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const PaginationTable = ({ filteredData, data, selectedOption, currentPage, itemsPerPage }) => {
    if (!filteredData || filteredData.length === 0) {
        return <p>Нет данных на данный промежуток</p>;
    }


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const renderItems = currentItems.map((value, i) => {

        let datetime = formatDateUnix(value.device_frmpayload_data_timestamp);
        const [date, time] = datetime.split(',')
        let volume = `${value.device_frmpayload_data_volume + data.device[0].data[0].start_volume}` * `${data.device[0].device[0].factor}`;
        const differenceValue = value.difference || 0;
        let consumption = 0;

        consumption = `${differenceValue}` * `${data.device[0].device[0].factor}`;
        consumption = consumption.toFixed(3)
        consumption += `${data.device[0].device[0].dev_type === 'ВС' ? ' м³'
            : data.device[0].device[0].dev_type === 'ТС' ? ' ГДж'
                : ' кВт-Ч'}`;

        volume = volume.toFixed(3);
        volume += `${data.device[0].device[0].dev_type === 'ВС' ? ' м³'
            : data.device[0].device[0].dev_type === 'ТС' ? ' ГДж'
                : ' кВт-Ч'}`;

        return (
            <tr key={i}>
                <td className={s.datetime}>
                    <div className={s.tableDate}>{date}</div>
                    <div className={s.tableTime}>{time}</div>
                </td>
                <td className={s.tableBodyText}>{volume}</td>
                <td className={s.tableBodyText}>{consumption}</td>
            </tr>
        );
    })

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }


    return (
        <>
            <>{renderItems}</>
        </>
    );
};

export function formatDateUnix(date) {
    let timestamp = date;
    let newDate = new Date(timestamp * 1000);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
    };
    newDate = newDate.toLocaleString('ru-RU', options);

    return newDate;
}

const otherSetting = {
    yAxis: [{ label: 'м³' }],
    grid: { horizontal: true },
    sx: {
        [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
        },
    },
};
const valueFormatter = (value) => `${value} м³`;


const CustomBarChart = ({ id, dev_number, aggr_type }) => {
    const [bar, setBar] = useState([])
    const [labels, setLabels] = useState([]);

    const today = new Date();
    const monthIndex = today.getMonth();
    const months = [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];
    const currentMonth = months[monthIndex];
    const previousMonth = months[monthIndex - 1]

    useEffect(() => {
        async function fetchDevice() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/reports/graph-day`);
                url.searchParams.append('_id', id);
                url.searchParams.append('dev_number', dev_number);
                url.searchParams.append('month', monthIndex + 1);
                url.searchParams.append('aggr_type', aggr_type);

                let response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();

                if (aggr_type == 1) {
                    const { current_month, previous_month } = data;
                    setBar([previous_month.difference, current_month.difference]);
                    setLabels([previousMonth, currentMonth]);
                }
                if (aggr_type == 0) {
                    const dailyValues = data;
                    const daysInMonth = new Date(today.getFullYear(), monthIndex + 1, 0).getDate();
                    setBar(Array.from({ length: daysInMonth }, (_, i) => dailyValues[i + 1]?.difference || 0));
                    setLabels(Array.from({ length: daysInMonth }, (_, i) => i + 1));
                }

            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchDevice();
    }, [aggr_type]);


    const [chartWidth, setChartWidth] = useState(window.innerWidth > 1600 ? 1128 : 690);
    const [chartHeigth, setCharteigth] = useState(window.innerWidth > 1600 ? 370 : 330);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth > 1600 ? 1128 : 690);
            setCharteigth(window.innerWidth > 1600 ? 370 : 330);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={s.chart}>
            <BarChart
                xAxis={[{ scaleType: 'band', data: labels }]}
                series={[{ data: bar, valueFormatter }]}
                width={chartWidth}
                height={chartHeigth}
                colors={['#466AE9']}
                {...otherSetting}
            >
            </BarChart>
        </div>
    );
}



function DeviceEdit({ closeModal, isOpen, deviceId, user }) {
    return (
        <div>
            <Modal
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                open={isOpen}
                onClose={closeModal}
                slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent sx={{ width: 1200, height: "90vh", overflowY: "auto" }}>
                    <EditForm deviceId={deviceId} user={user} />
                </ModalContent>
            </Modal>
        </div>
    );
}

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Modal = styled(BaseModal)(`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.base-Modal-hidden {
    visibility: hidden;
  }
`);

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);


const EditForm = ({ deviceId, user }) => {
    const [submitStatus, setSubmitStatus] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [initialValues, setInitialValues] = useState({
        address: {
            country: '',
            region: '',
            city: '',
            street: '',
            building: ''
        },
        address_number: '',
        device_iot: {
            dev_type_iot: '',
            brand_iot: '',
            deveui: '',
            serialnumber: '',
            token: '',
            manufacture: '',
        },
        device: {
            dev_type: '',
            dev_number: '',
            resourse_type: '',
            brand: '',
            diameter_body: '',
            commit: '',
            verification_end_date: '',
            factor: ''
        },
        paid_info: {
            dev_number: '',
            registry: '',
            ownership: '',
            devicecode: '',
            accountnumber: '',
            subscriptionarea: ''
        },
        chanel: null
    });

    console.log(initialValues);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchDeviceData(deviceId);
            console.log(response);
            const data = response;
            setInitialValues({
                address: data.address,
                address_number: data.address_number,
                device_iot: data.device_iot,
                device: data.device[0],
                paid_info: data.paid_info[0],
                chanel: data.chanel
            });
        };

        fetchData();
    }, [deviceId]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                const [deviceUuid, deviceNumber] = deviceId.split('&');
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/edit`);
                url.searchParams.append('user', user.email);
                url.searchParams.append('_id', deviceUuid);
                url.searchParams.append('dev_number', deviceNumber);
                let response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });
                let data = await response.json();
                setSubmitStatus('success');
                setSubmitting(false);
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        },
        enableReinitialize: true,
    })

    const handleFormClick = (event) => {
        event.stopPropagation();
    };

    if (!formik.values.address) return null;

    return (

        <div className={s.editContainer}>
            <form onSubmit={formik.handleSubmit} onClick={handleFormClick}>
                {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
                {submitStatus === 'error' && <div className={s.errorMessage}>{s.errorMessage}</div>}
                <div className={s.grid}>
                    <Box sx={{ width: "auto", flex: 1 }}>
                        <Typography variant="h5">
                            Адрес
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address.country"
                            name="address.country"
                            label="Страна"
                            value={formik.values.address.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address.region"
                            name="address.region"
                            label="Область"
                            value={formik.values.address.region}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address.city"
                            name="address.city"
                            label="Город"
                            value={formik.values.address.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address.street"
                            name="address.street"
                            label="Улица"
                            value={formik.values.address.street}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address.building"
                            name="address.building"
                            label="Дом"
                            value={formik.values.address.building}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="address_number"
                            name="address_number"
                            label="Квартира(Помещение)"
                            value={formik.values.address_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>

                    <Box sx={{ width: "auto", flex: 1 }}>
                        <Typography variant="h5">
                            IoT Устройство
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.dev_type_iot"
                            name="device_iot.dev_type_iot"
                            label="Тип устройства"
                            value={formik.values.device_iot.dev_type_iot}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.brand_iot"
                            name="device_iot.brand_iot"
                            label="Марка"
                            value={formik.values.device_iot.brand_iot}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.deveui"
                            name="device_iot.deveui"
                            label="DevEUI (LoRa)"
                            value={formik.values.device_iot.deveui}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.serialnumber"
                            name="device_iot.serialnumber"
                            label="Серийный номер (NBIoT)"
                            value={formik.values.device_iot.serialnumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.token"
                            name="device_iot.token"
                            label="Токен"
                            value={formik.values.device_iot.token}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device_iot.manufacture"
                            name="device_iot.manufacture"
                            label="Производитель устройства"
                            value={formik.values.device_iot.manufacture}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>

                    <Box sx={{ width: "auto", flex: 1 }}>
                        <Typography variant="h5">
                            Платежная Информация
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="paid_info.registry"
                            name="paid_info.registry"
                            label="Реестр"
                            value={formik.values.paid_info.registry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="paid_info.ownership"
                            name="paid_info.ownership"
                            label="Владелец"
                            value={formik.values.paid_info.ownership}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="paid_info.devicecode"
                            name="paid_info.devicecode"
                            label="Код устройства в ЕРИП"
                            value={formik.values.paid_info.devicecode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="paid_info.accountnumber"
                            name="paid_info.accountnumber"
                            label="Лицевой счет"
                            value={formik.values.paid_info.accountnumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="paid_info.subscriptionarea"
                            name="paid_info.subscriptionarea"
                            label="Абонентский район"
                            value={formik.values.paid_info.subscriptionarea}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>


                    <Box sx={{ width: "auto", flex: 1 }}>
                        <Typography variant="h5">
                            Устройство
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.dev_type"
                            name="device.dev_type"
                            label="Тип устройства"
                            value={formik.values.device.dev_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.dev_number"
                            name="device.dev_number"
                            label="Заводской номер"
                            value={formik.values.device.dev_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.resourse_type"
                            name="device.resourse_type"
                            label="Тип ресурса"
                            value={formik.values.device.resourse_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.brand"
                            name="device.brand"
                            label="Марка"
                            value={formik.values.device.brand}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.diameter_body"
                            name="device.diameter_body"
                            label="Диаметр устройства"
                            value={formik.values.device.diameter_body}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.commit"
                            name="device.commit"
                            label="Комментарий"
                            value={formik.values.device.commit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.verification_end_date"
                            name="device.verification_end_date"
                            label="Дата окончания поверки"
                            value={formik.values.device.verification_end_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextField
                            size='small'
                            fullWidth
                            margin="normal"
                            id="device.factor"
                            name="device.factor"
                            label="Множитель"
                            value={formik.values.device.factor}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                </div>
                <Button
                    color="primary"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}>
                    Отправить
                </Button>
            </form>
        </div>

    );
}


