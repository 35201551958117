import React, { useState, useEffect } from 'react';

import s from "./Layout.module.css"
import Settings from '../Settings/Settings';
import useIsMobile from '../../store/mobileHook';
import SettingMenu from '../SettingsMenu/SettingsMenu';




export default function LayoutSettings() {
    const isMobile = useIsMobile();

    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeMenuItem, setActiveMenuItem] = useState('item4')
    const [activeComponent, setActiveComponent] = useState('Settings');

    const [selectedMenu, setSelectedMenu] = useState(null);

    const handleMenuChange = (menu) => {
        setSelectedMenu(menu);
    };

    return (

        <div className={s.container}>
            {!isMobile ? (
                <>
                    {activeSideBar ? (
                        <SettingMenu setActiveSideBar={setActiveSideBar} setActiveMenuItem={setActiveMenuItem} onMenuChange={handleMenuChange} />
                    ) : (
                        <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                            <img src={'/showlogo.svg'}></img>
                        </div>
                    )}
                </>
            ) : (
                <></>
            )
            }
            <div className={s.content}>
                <Settings selectedMenu={selectedMenu} handleMenuChange={handleMenuChange} />
            </div>
        </div>

    )
}