import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const ModalTemplate = ({
    open,
    onClose,
    children
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
};

export default ModalTemplate;
