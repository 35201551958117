import React, { useState, useRef, useEffect, useContext } from 'react';
import TreeDiv from '../AddressTree/AddressTree';
import s from "./Reports.module.css"
import { Link } from 'react-router-dom';
import { ReportChoiceData } from '../ReportUtils/ReportUtils';
import ReportButtons from '../ButtonsGroup/ReportButtons';
import { exportToExel, utilsDatetime, exportErip } from '../../store/exportToExcel';
import { formatDateUnix } from '../Layout/DeviceInfo';
import { UserContext } from '../../App';
import CircularProgress from "@mui/material/CircularProgress";



function Reports({ handleLogsClick }) {
    const [loading, setLoading] = useState(false);

    const [utilsData, setutilsData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchAddressQuery, setSearchAddressQuery] = useState('');
    const { user, setUser } = useContext(UserContext);
    const [total, setTotal] = useState(null)
    const [devicesList, setDevice] = useState([])
    const [fullAddress, setFullAddress] = useState([]);
    const [openTree, setOpenTree] = useState(false)
    const popupRef = useRef(null);
    const buttonRef = useRef(null);
    const [filterType, setFilterType] = useState('Все');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);

    const [sortOrder, setSortOrder] = useState({});
    const [order, setOrder] = useState('')

    const tableProps = {
        devicesList,
        setDevice,
        total,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemPerPage,
        sortOrder,
        setSortOrder,
        order,
        setOrder,
        utilsData,
        setutilsData,
        handleLogsClick,
        filterType,
        setFilterType,
        fullAddress,
        searchQuery,
        user,
        loading
    }

    useEffect(() => {
        async function fetchDevice() {
            setLoading(true);
            try {
                let [selectedOption, toDate, fromDate] = utilsData;
                const formatDateToRFC3339 = (date) => date.toISOString();
                const getDateBoundary = (date, option) => {
                    const newDate = new Date(date);
                    if (option === 'startOfMonth') {
                        return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 59, 59, 999));
                    } else if (option === 'startOfYear') {
                        const lastMonthOfYear = 11;
                        newDate.setMonth(lastMonthOfYear);
                        newDate.setDate(31);
                        return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999));
                    }
                    return new Date(`${date}T23:59:59.999Z`);
                };

                const fetchToDate = formatDateToRFC3339(getDateBoundary(toDate, selectedOption));
                const fetchFromDate = formatDateToRFC3339(new Date(fromDate));
                const url = new URL(`${process.env.REACT_APP_API_URL}/reports/get`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });

                url.searchParams.append('limit', itemsPerPage);
                url.searchParams.append('offset', (currentPage * itemsPerPage) - itemsPerPage);
                url.searchParams.append('address', fullAddress)
                url.searchParams.append('search', searchQuery)
                url.searchParams.append('order', order)
                url.searchParams.append('detail', selectedOption);
                url.searchParams.append('toDate', fetchToDate);
                url.searchParams.append('fromDate', fetchFromDate);
                url.searchParams.append('filterType', filterType);

                let response = await fetch(url);
                const data = await response.json();

                const { devices } = data
                const { total } = data
                const result = devices.flatMap(item =>
                    item.result.map(result => ({ ...result, device: item }))
                );

                setDevice(result);
                setTotal(total);
            } catch (error) {
                console.error('Error fetching logs:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchDevice();
    }, [currentPage, itemsPerPage, fullAddress, searchQuery, order, utilsData, filterType]);


    const handleFullAddressChange = (address) => {
        if (JSON.stringify(fullAddress) !== JSON.stringify(address)) {
            setFullAddress(address);
        }
    };


    const handleItemClick = (event) => {
        event.stopPropagation();
        setOpenTree(true);
    };

    const cancelClick = () => {
        setFullAddress([]);
        setDevice([]);

    }

    const handleClickOutside = (event) => {
        if (
            popupRef.current && !popupRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpenTree(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={s.main}>
            <div className={s.searchFrame}>
                <input
                    type='text'
                    className={s.search}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder='Поиск по imei, devEUI или номеру'
                >
                </input>
                <img src={'/search.svg'}></img>
            </div>

            <div className={s.addressWarp}>
                <div className={s.addressFrame} onClick={handleItemClick} ref={buttonRef}>
                    <div className={s.frame75} >
                        <div className={s.frame75logo}>
                            <img src={'/home.svg'}></img>
                        </div>
                        <div className={s.frame75Text}>Список адресов</div>
                        <div className={s.arrowDown}>
                            <img src={'/arrowback.svg'}></img>

                        </div>
                    </div>

                    {openTree && (
                        <div className={s.addressPopup} ref={popupRef}>
                            <div className={s.address}>
                                <div className={s.addressInput}>
                                    <input
                                        type='text'
                                        className={s.addressSearch}
                                        value={searchAddressQuery}
                                        onChange={(e) => setSearchAddressQuery(e.target.value)}
                                        placeholder='Поиск адреса'
                                    >
                                    </input>
                                    <img src={'/search.svg'}></img>
                                </div>
                                <div>
                                    <TreeDiv setSelectedAddress={(address) => {
                                        setSelectedAddress(address);
                                    }} handleFullAddressChange={handleFullAddressChange} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {fullAddress.length > 0 && (
                    <div className={s.chosedAddr} onClick={cancelClick} >
                        <div className={s.chosedAddrImg}>
                            <img
                                src={'/cancel.svg'}
                                alt="cancel"
                            />
                        </div>

                        <div className={s.chosedAddrText}>
                            {fullAddress.join(', ')}
                        </div>

                    </div>
                )}
            </div>
            <div className={s.wrapper}>

                <Table {...tableProps} />
            </div>
        </div>

    )
}
export default Reports;


const Table = (tableProps) => {
    const headers = ['Номер счетчика', 'Адрес', 'Тип', 'Показание на конец выбранного периода', 'Потребление за выбранный период', 'Дата последней передачи', 'Детали'];
    const { devicesList, setDevice, total, currentPage, 
        setCurrentPage, itemsPerPage, setItemPerPage, user,
        sortOrder, setSortOrder, order, setOrder, fullAddress, searchQuery,
        utilsData, setutilsData, handleLogsClick, filterType, setFilterType, loading } = tableProps

    const handleExportXLSX = () => {
            const readingdate = utilsDatetime()
            const dataToExport = {
                utilsData,
                filterType,
                fullAddress,
                searchQuery,
                order,
                readingdate,
                user
            };
            exportToExel({ dataToExport })
                .then(() => {
                    console.log('Данные успешно отправлены для отчета за период');
                })
                .catch(error => {
                    console.error('Ошибка отправки данных для отчета за период:', error);
                });
        };    

    const filteredDevices = []

    const headerKeys = {
        'Номер счетчика': 'dev_number',
        'Адрес': 'address',
    };

    const handleDataFromChild = (data) => {
        setutilsData(data);
    };

    const sortData = (field) => {
        const newSortOrder = sortOrder[field] === 1 ? -1 : 1;

        setSortOrder({
            ...sortOrder,
            [field]: newSortOrder
        });

        const orderField = newSortOrder === 1 ? field : `-${field}`;
        setOrder(orderField);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(total / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const totalPages = Math.ceil(total / itemsPerPage);

    

    const handleExportCSV = () => {
        const originalData = filteredDevices;
        exportErip(originalData);
    };



    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        if (totalPages > 1) {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        }
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleDevType = (event) => {
        setFilterType(event);
        setDevice([]);
    }

    const renderItems = devicesList.map((result, index) => (
        <tr key={`${index}`}>
            {headers.map((header) => (
                <td key={header} >
                    <div className={s.tableBodyText}>
                        {header === 'Детали' ? (
                            <Link to={`/device/${result.device._id}&${result.device.device[0].dev_number}`} id={`${result.device.device[0].dev_number}`}>
                                <img src={'/document.svg'} />
                            </Link>
                        ) : header === 'Тип' ? (

                            result.device.device[0].dev_type === "ВС" && result.device.device[0].resourse_type === "Горячая вода" ? "ГВС"
                                : result.device.device[0].dev_type === "ВС" && result.device.device[0].resourse_type === "Холодная вода" ? "ХВС"
                                    : result.device.device[0].dev_type === "ТС" ? "ТС"
                                        : "ВС"

                        )
                            : header === 'Номер счетчика' ? (
                                result.device.device[0].dev_number
                            ) : header === 'Адрес' ? (
                                `${result.device.address[0].country ? result.device.address[0].country + ', ' : ''}` +
                                `${result.device.address[0].region ? result.device.address[0].region + ', ' : ''}` +
                                `${result.device.address[0].city ? result.device.address[0].city + ', ' : ''}` +
                                `${result.device.address[0].street ? result.device.address[0].street + ', ' : ''}` +
                                `${result.device.address[0].building ? result.device.address[0].building + (result.device.address_number !== null ? ', ' : '') : ''}` +
                                `${result.device.address_number !== null && result.device.address_number !== undefined ? result.device.address_number : ''}`
                            ) : header === 'Показание на конец выбранного периода' ? (
                                `${(parseFloat(result.device_frmpayload_data_volume + result.device.data.start_volume) * (result.device.device[0].factor)).toFixed(3)} 
                                ${result.device.device[0].dev_type === 'ВС' ? 'м³'
                                    : result.device.device[0].dev_type === 'ТС' ? 'ГДж'
                                        : 'кВт-Ч'}`
                            ) : header === 'Потребление за выбранный период' ? (
                                `${(parseFloat(result.difference) * (result.device.device[0].factor)).toFixed(3)} 
                                ${result.device.device[0].dev_type === 'ВС' ? 'м³'
                                    : result.device.device[0].dev_type === 'ТС' ? 'ГДж'
                                        : 'кВт-Ч'}`
                            ) : header === 'Дата последней передачи' ? (

                                result.device_frmpayload_data_timestamp !== 0
                                    ? formatDateUnix(result.device_frmpayload_data_timestamp)
                                    : result.device.data.last_date_time !== 0
                                        ? formatDateUnix(result.device.data.last_date_time)
                                        : 'нет данных'

                            )
                                : (
                                    result[header]
                                )}
                    </div>
                </td>
            ))}
        </tr>
    ))


    return (
        <>
            <ReportButtons handleExportCSV={handleExportCSV} handleExportXLSX={handleExportXLSX} handleLogsClick={handleLogsClick}/>
            <div className={s.tableContainer}>
                <div className={s.warp}>
                    <ReportChoiceData sendDataToParent={handleDataFromChild} />
                    <div className={s.typeFrame}>
                        <div className={s.types}>Тип устройства: </div>
                        <select className={s.tableSelect} onChange={(e) => handleDevType(e.target.value)}>
                            <option value='Все'>Все</option>
                            <option value='ВС'>ВС</option>
                            <option value='ЭС'>ЭС</option>
                            <option value='ТС'>ТС</option>
                        </select>
                    </div>
                    <div className={s.table}>

                        <div className={s.tableHeader}>
                            <table>
                                <thead>
                                    <tr>
                                        {headers.map((header) => (
                                            <th key={header} onClick={() => headerKeys[header] && sortData(headerKeys[header])}>
                                                <div className={s.warps}>
                                                    <div className={s.frame48Text}>{header}</div>
                                                    {header === 'Номер счетчика' || header === 'Адрес' ? (
                                                        <div className={s.tableArrowDown}>

                                                            <img
                                                                src={sortOrder[headerKeys[header]] === 1 ? '/tablearrowup.svg' : '/tablearrowdown.svg'}
                                                                alt="sort"
                                                            />
                                                        </div>) : (null)}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className={s.tableBody}>
                            <table>
                                <tbody>
                                    {loading && (
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    <>{renderItems}</>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={s.paginate}>
                        <div className={s.paginateText}>{total} всего</div>
                        <div className={s.devicePerPage}>
                            <div className={s.devicePerPageText}>Показать</div>
                            <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className={s.paginateInput}>
                            <div className={s.arrowLeft} onClick={handlePreviousPage}>
                                <img src={'/arrowleft.svg'}></img>
                            </div>
                            <div className={s.page}>{currentPage}</div>
                            <div className={s.arrowRight} onClick={handleNextPage}>
                                <img src={'/arrowright.svg'}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

