import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../App";
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../src/i18n';
import { ruRU } from '@mui/x-data-grid/locales'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UserEdit from './UsersEdit';


function UsersTable({ onMenuChange }) {
  

  const { user, setUser } = useContext(UserContext);
  const [editingEmail, setEditingEmail] = useState(null);
  const [data, setData] = useState([])
  const { t } = useTranslation();

  const theme = createTheme(
    ruRU
  );

  useEffect(() => {
    async function fetchData() {
      if (user && user.email) {
        try {
          const url = new URL(`${process.env.REACT_APP_API_URL}/users/list`);

          url.searchParams.append('email', user.email);

          let response = await fetch(url);
          let data = await response.text();
          let obj = JSON.parse(data);
          setData(obj)
        } catch (error) {
          console.error('Error fetching device:', error);
        }
      }
    }
    fetchData();
  }, [user]);

  const deleteUser = async (email) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_URL}/users/delete`);

      url.searchParams.append('email', email);

      let response = await fetch(url, {
        method: 'POST'
      });
      if (response.ok) {
        console.log("User deleted successfully");
      } else {
        throw new Error('Failed to delete the user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }

  const editUser = (email) => {
    if (!editingEmail) {
      setEditingEmail(email);
    }

  };

  const closeModal = () => {
    setEditingEmail(null);
  };

  const rows = data.map(item => ({
    id: item._id,
    email: item.email,
    role: t(item.role),
    fullname: item.fullname,
    phone: item.phone
  }))


  const columns = [
    { field: 'email', headerName: t('email'), width: 200 },
    { field: 'role', headerName: t('role'), width: 220 },
    { field: 'fullname', headerName: t('fullname'), width: 200 },
    { field: 'phone', headerName: t('phone'), width: 200 },
    {
      field: 'action',
      headerName: t('action'),
      width: 300,
      sortable: false,
      renderCell: (params) => (

        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ m: 1 }}
            onClick={(event) => {
              event.stopPropagation();
              editUser(params.row.email);
            }}
          >
            {t('edit')}
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => { deleteUser(params.row.email) }}
          >
            {t('delete')}
          </Button>
        </>
      ),
    },
  ];



  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            marginBottom: 1
          }}
          onClick={() => onMenuChange('createUser')}
        >
          {t('createUser')}
        </Button>
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <DataGrid
            columns={columns}
            rows={rows}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            autoHeight
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </Box>
        {editingEmail && (
          <UserEdit isOpen={!!editingEmail} closeModal={closeModal} email={editingEmail} />
        )}
      </Box>
    </ThemeProvider>
  )



}

export default UsersTable;

