import { React, useState, useEffect, useContext } from 'react';
import s from "../SettingData/SettingData.module.css"
import { UserContext } from "../../App";
import { useFormik } from 'formik';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Typography } from '@mui/material';
import { useHideMessage } from '../SettingData/Forms';
import ModalTemplate from '../ModalForms/ModalForms';

export const NbiotOrgForm = ({ onSuccess }) => {
    const { user, setUser } = useContext(UserContext);

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/nbiot/create-org`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.email,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                

                const user_response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
                    method: 'GET',
                    headers: {
                        'X-Username': user.email,
                    },
                });

                const user_data = await user_response.json();
                setUser(user_data)                

                if (onSuccess) {
                    onSuccess(data.org);
                }

                setSubmitStatus('success');
                resetForm();

            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Создание организации
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <TextField
                fullWidth
                margin="normal"
                id="name"
                name="name"
                label="Название"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}


export const NbiotGroupForm = ({ onSuccess }) => {
    const { user, setUser } = useContext(UserContext);

    const [openOrg, setOpenOrg] = useState(false);
    const handleOpenOrg = () => setOpenOrg(true);
    const handleCloseOrg = () => setOpenOrg(false);

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            organization: ""
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/nbiot/create-group`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.email,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                const user_response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
                    method: 'GET',
                    headers: {
                        'X-Username': user.email,
                    },
                });

                const user_data = await user_response.json();
                setUser(user_data)

                if (onSuccess) {
                    onSuccess(data.group);
                }

                setSubmitStatus('success');
                resetForm();

            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const handleOrgSuccess = (newOrg) => {
        formik.setFieldValue('organization', newOrg._id);
        handleCloseOrg();
    };

    useEffect(() => {
        if (user.nbiot_org) {
            formik.setFieldValue('organization', formik.values.organization || '');
        }
    }, [user]);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Создание группы
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <TextField
                fullWidth
                margin="normal"
                id="name"
                name="name"
                label="Название"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Название организации</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="organization"
                    name="organization"
                    label="Название организации"
                    value={formik.values.organization || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.organization && Boolean(formik.errors.organization)}
                    helperText={formik.touched.organization && formik.errors.organization}
                >   
                    <MenuItem value="" onClick={handleOpenOrg}>
                        + Создать новую организацию
                    </MenuItem>
                    {user.nbiot_org.map(org => (
                        <MenuItem value={org._id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
                <ModalTemplate
                    open={openOrg}
                    onClose={handleCloseOrg}
                >
                    <NbiotOrgForm onSuccess={handleOrgSuccess} />
                </ModalTemplate>
            </FormControl>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}
