import React, { useState, useEffect } from 'react';
import s from "./SettingsMenu.module.css"
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';
import { List, Collapse, ListItemButton, ListItemText } from '@mui/material';

export default function SettingMenu({ onMenuChange, setActiveSideBar, setActiveComponent }) {
    const isMobile = useIsMobile();

    const [activeItem, setActiveItem] = useState('');

    const [dropdowns, setDropdowns] = useState({});

    const toggleDropdown = (id) => {
        setDropdowns(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const navigate = useNavigate();

    const handleBackClick = () => {
        // onBackClick();
        navigate('/devices');
    };



    return (
        <div className={s.sideBarFrame}>
            <div className={s.forwardback} onClick={handleBackClick}>
                <div className={s.backIcon}>
                    <img src={'/forwardback.svg'}></img>
                </div>
                <div className={s.forwardbackText}>Назад</div>
            </div>
            <div className={s.sideBarBox}>
                <div className={s.sideBarGroup}>
                    <div
                        className={`${s.item1} ${activeItem === 'item1' ? s.active : ''}`}
                        onClick={() => toggleDropdown('lora')}
                    >
                        <div className={s.locationText}>Настройки LoRa</div>
                    </div>
                    <Collapse in={dropdowns.lora} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 3 }} key={'createTenant'} onClick={() => onMenuChange('createTenant')}>
                                <ListItemText primary="Организаций" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 3 }} key={'createApplication'} onClick={() => onMenuChange('createApplication')}>
                                <ListItemText primary="Группы" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 3 }} key={'createProfile'} onClick={() => onMenuChange('createProfile')}>
                                <ListItemText primary="Профиль устройства" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 3 }} key={'createGateway'} onClick={() => onMenuChange('createGateway')}>
                                <ListItemText primary="Шлюзы" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <div
                        className={`${s.item2} ${activeItem === 'item2' ? s.active : ''}`}
                        onClick={() => toggleDropdown('nbiot')}
                    >
                        <div className={s.vibrationText}>Найтройки NBIoT</div>
                    </div>
                    <Collapse in={dropdowns.nbiot} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 3 }} key={'createNbiotOrg'} onClick={() => onMenuChange('createNbiotOrg')}>
                                <ListItemText primary="Организаций" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 3 }} key={'createNbiotGroup'} onClick={() => onMenuChange('createNbiotGroup')}>
                                <ListItemText primary="Группы" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <div
                        className={`${s.item3} ${activeItem === 'item3' ? s.active : ''}`}
                        key={'users'} onClick={() => onMenuChange('users')}
                    >
                        <div className={s.articleText}>Пользователи</div>
                    </div>
                    {/* <div
                        className={`${s.item3} ${activeItem === 'item4' ? s.active : ''}`}
                        key={'users'} onClick={() => onMenuChange('users')}
                    >
                        <div className={s.articleText}>Адреса</div>
                    </div> */}
                </div>
            </div>
            
            {/* {!isMobile ? ( */}
                {/* <div className={s.arrowBack}>  onClick={() => setActiveSideBar(false)}> */}
                    {/* <img src={'/hidelogo.svg'}></img> */}
                {/* </div> */}
            {/* ) : ( */}
                {/* <></> */}
            {/* )} */}


        </div>


    )

}

